import { apiUrl } from './Api';
import Axios from 'axios';

const checkYesNo = (value) => {
    return value === 0 || value === null || value === undefined ? 'Não' : 'Sim';
};

export async function createProposal(
    auth,
    client,
    saveOptions,
    specificationPercentages,
    profileMapPercentage,
    servicesPercentages,
    paymentSubPrice
) {
    const rtvId = client.saId ?? auth.data.user.saId;
    const formatDate = saveOptions.deadline?.split('/');
    const deadline = formatDate
        ? `${formatDate[2]}-${formatDate[0]}-${formatDate[1]}`
        : null;

    const fertilizers = {};
    if (saveOptions.compost1 !== null && saveOptions.compost1[0] !== '') {
        fertilizers[saveOptions.compost1.name] = saveOptions.compost1.baseValue;
    }
    if (saveOptions.compost2 !== null && saveOptions.compost1[0] !== '') {
        fertilizers[saveOptions.compost2.name] = saveOptions.compost2.baseValue;
    }

    try {
        return await Axios.post(
            `${apiUrl}/proposals`,
            {
                grid: saveOptions.grid,
                haMade: client.areaMade ? client.areaMade : client.ha,
                cycle: saveOptions.cycle,
                isTotalArea: checkYesNo(saveOptions.isTotalArea), // areamade no banco
                parcels: saveOptions.parcels,
                profileMap: profileMapPercentage,
                monitoring: checkYesNo(specificationPercentages.monitorament),
                noAccompaniment:
                    specificationPercentages.accompaniment === null ||
                    specificationPercentages.accompaniment === 0
                        ? 'Sim'
                        : 'Não',
                urgency: checkYesNo(specificationPercentages.seasonality),
                deadline: specificationPercentages.seasonality
                    ? deadline
                    : null,
                seasonality: saveOptions.seasonality,
                specificLabPrice: specificationPercentages.laboratory,
                services: servicesPercentages,
                status: 'Em Andamento',
                initialAmount: paymentSubPrice,
                finalAmount: paymentSubPrice,
                comments: null,
                laboratoryId: saveOptions.laboratoryId,
                clientId: client.clientId,
                saId: rtvId,
                stateId: client.stateId,
                noHarvestMap:
                    specificationPercentages.harvestMap === null ||
                    specificationPercentages.harvestMap === 0
                        ? 'Sim'
                        : 'Não',
                soyValue: saveOptions.soyValue,
                observations: saveOptions.observations,
                creatorId: auth.data.user.saId,
                fertilizers,
                expectedCollectDate: saveOptions.expectedCollectDate,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function updateProposal(
    auth,
    client,
    saveOptions,
    specificationPercentages,
    profileMapPercentage,
    servicesPercentages,
    paymentSubPrice,
    proposalId
) {
    try {
        const formatDate = saveOptions.deadline?.split('/');
        const deadline = formatDate
            ? `${formatDate[2]}-${formatDate[0]}-${formatDate[1]}`
            : null;

        const fertilizers = {};
        if (saveOptions.compost1 !== null) {
            fertilizers[saveOptions.compost1.name] =
                saveOptions.compost1.baseValue;
        }
        if (saveOptions.compost2 !== null) {
            fertilizers[saveOptions.compost2.name] =
                saveOptions.compost2.baseValue;
        }

        return await Axios.put(
            `${apiUrl}/proposals/${proposalId}`,
            {
                grid: saveOptions.grid,
                haMade: saveOptions.areaMade ? saveOptions.areaMade : client.ha,
                cycle: saveOptions.cycle,
                isTotalArea: checkYesNo(saveOptions.isTotalArea),
                parcels: saveOptions.parcels,
                profileMap: profileMapPercentage,
                monitoring: checkYesNo(specificationPercentages.monitorament),
                noAccompaniment:
                    specificationPercentages.accompaniment === null ||
                    specificationPercentages.accompaniment === 0
                        ? 'Sim'
                        : 'Não',
                urgency: checkYesNo(specificationPercentages.seasonality),
                deadline: specificationPercentages.seasonality
                    ? deadline
                    : null,
                seasonality: saveOptions.seasonality,
                specificLabPrice: specificationPercentages.laboratory,
                services: servicesPercentages,
                initialAmount: paymentSubPrice,
                finalAmount: paymentSubPrice,
                laboratoryId: saveOptions.laboratoryId,
                noHarvestMap:
                    specificationPercentages.harvestMap === null ||
                    specificationPercentages.harvestMap === 0
                        ? 'Sim'
                        : 'Não',
                soyValue: saveOptions.soyValue,
                observations: saveOptions.observations,
                creatorId: auth.data.user.saId,
                expectedCollectDate: saveOptions.expectedCollectDate,
                fertilizers,
            },
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function updateProposalStatus(
    auth,
    proposalId,
    status,
    finalValue
) {
    let body = { status };
    if (finalValue) {
        body.finalAmount = finalValue;
    }
    try {
        return await Axios.put(
            `${apiUrl}/proposals/${proposalId}/updateProposalStatus`,
            body,
            {
                headers: {
                    Authorization: `Bearer ${auth.data.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
    } catch (err) {
        return err.response;
    }
}

export async function findProposalsFromYear(auth, saId, stateId, year) {
    try {
        const params = {
            year,
            stateId,
        }
        return await Axios.get(
            `${apiUrl}/proposals/sas/${saId}/countProposalsStatusByYear`,
            {
                params:  params ,
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findYearOfTheProposals(auth, saId) {
    try {
        return await Axios.get(
            `${apiUrl}/proposals/sas/${saId}/findYearOfTheProposals`,
            {
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findProposalValuesFromMetas(auth, saId, year) {
    try {
        return await Axios.get(
            `${apiUrl}/proposals/sas/${saId}/valueOfProposalsAccepted`,
            {
                params: { year },
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findProposal(auth, proposalId) {
    try {
        return await Axios.get(
            `${apiUrl}/proposals/${proposalId}/findProposal`,
            {
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findPricingProposal(auth, proposalId) {
    try {
        return await Axios.get(
            `${apiUrl}/proposals/${proposalId}/findPricingProposal`,
            {
                headers: { Authorization: `Bearer ${auth.data.token}` },
            }
        );
    } catch (err) {
        return err;
    }
}

export async function findProposalsWithFilters(
    auth,
    saId,
    isDashboard,
    clientId = null,
    grid = null,
    status = null,
    stateId = null,
    startDate = null,
    finalDate = null
) {
    try {
        const params = {
            saId,
            status,
            clientId,
            grid,
            stateId,
            period: startDate && finalDate ? `${startDate.toISOString()},${finalDate.toISOString()}` : null,
        };
        //if (isDashboard) params.status = 'Enviada,Em Andamento';
        return await Axios.get(`${apiUrl}/proposals/findProposalsWithFilters`, {
            params: params,
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllProposalsValues(auth, year) {
    try {
        return await Axios.get(`${apiUrl}/proposals/findAllProposalsValues`, {
            params: { year },
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findAllProposalsStatus(auth, year) {
    try {
        return await Axios.get(`${apiUrl}/proposals/findAllProposalsStatus`, {
            params: { year },
            headers: { Authorization: `Bearer ${auth.data.token}` },
        });
    } catch (err) {
        return err;
    }
}

export async function findStatusAndProposals(auth, stateId, saId, clientId, year) {
    try {
        return await Axios.get(`${apiUrl}/proposals/findStatusAndProposals`, {
            headers: { Authorization: `Bearer ${auth.data.token}` },
            params: { stateId, saId, clientId, year },
        });
    } catch (err) {
        return err;
    }
}
